.obc-Box{
    background-image: url(./image/Membership\ Event\ 1920x1080.jpg);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    height: 90vh;
    /* background-attachment: fixed; */
    background-position: center;
    /* background-position: center center; */
}
.selectboxobc{
    position: absolute;
    top: 55%;
    left: 37%;
    width: 25%;
    z-index: 10;
}

@media (max-width: 1070px) {
    .obc-Box{
        background-image: url(./image/Membership\ Event\ 1920x1080.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 90vh;
        background-position: center;
    }
    .selectboxobc{
        position: absolute;
        top: 58%;
        left: 37%;
        width: 25%;
        z-index: 10;
    }
  }

  @media (max-width: 940px) {
    .obc-Box{
        background-image: url(./image/Membership\ Event\ 1920x1080.jpg);
        background-size: 100% 100%; 
        background-repeat: no-repeat;
        height: 100vh;
        background-position: center;
    }
  }
 @media (max-width: 705px) {
    .obc-Box{
        background-image: url(./image/WhatsApp\ Image\ 2024-09-06\ at\ 9.59.14\ PM.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        background-position: center;
    }
    .selectboxobc{
        position: absolute;
        top: 40%;
        left: 35%;
        width: 30%;
        z-index: 10;
        height: 40px;
    }
    .selectboxobc div{
        height: 40px;
    }
  } 

@media (max-width: 500px) {
    .selectboxobc{
        display: none;
    }
  }
@media (max-width: 450px) {
    .obc-Box{
        background-image: url(./image/Membership\ Event\ 800x1080\ \(1\).jpg);
        background-size: cover; 
        background-repeat: no-repeat;
        height: 80vh;
        margin-bottom: 20px;
    }
    .selectboxobc{
        position: absolute;
        top: 95%;
        left: 15%;
        width: 70%;
        z-index: 10;
    }
  }
