.As-Box{
    background-image: url(./IMG_1907.jpeg);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    height: 90vh;
    /* background-attachment: fixed; */
    background-position: center;
    /* background-position: center center; */
}
.selectbox{
    position: absolute;
    top: 55%;
    left: 37%;
    width: 25%;
    z-index: 10;
}

@media (max-width: 800px) {
    .As-Box{
        background-image: url(./As/800x1080\ 5-1.jpg);
        background-size: cover; 
        background-repeat: no-repeat;
        height: 100vh;
        background-position: center;
    }
  }
@media (max-width: 600px) {
    .selectbox{
        display: none;
    }
  }
/* @media (max-width: 500px) {
    .As-Box{
        background-image: url(/public/image/As/m2.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 80vh;
        background-position: center;
    }
    .selectbox{
        position: absolute;
        top: 83%;
        left: 15%;
        width: 70%;
        z-index: 10;
    }
  } */
@media (max-width: 500px) {
    .As-Box{
        background-image: url(./As/m2.jpeg);
        background-size: 100% 100%; 
        background-repeat: no-repeat;
        height: 80vh;
        margin-bottom: 100px;
    }
    .selectbox{
        position: absolute;
        top: 95%;
        left: 15%;
        width: 70%;
        z-index: 10;
    }
  }
