.kishan-Box{
    background-image: url(./images/kisan\ 1920.jpg);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    height: 90vh;
    /* background-attachment: fixed; */
    background-position: center;
    /* background-position: center center; */
}
.selectboxkisan{
    position: absolute;
    top: 55%;
    left: 37%;
    width: 25%;
    z-index: 10;
}

@media (max-width: 1070px) {
    .kishan-Box{
        background-image: url(./images/kisan\ 1920.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 90vh;
        background-position: center;
    }
    .selectboxkisan{
        position: absolute;
        top: 55%;
        left: 37%;
        width: 25%;
        z-index: 10;
    }
  }

  @media (max-width: 940px) {
    .kishan-Box{
        background-image: url(./images/kisan\ 1920.jpg);
        background-size: 100% 100%; 
        background-repeat: no-repeat;
        height: 100vh;
        background-position: center;
    }
  }
 @media (max-width: 805px) {
    .kishan-Box{
        background-image: url(./images/kisan\ 1200.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        background-position: center;
    }
    .selectboxkisan{
        position: absolute;
        top: 38%;
        left: 35%;
        width: 30%;
        z-index: 10;
    }
  } 

@media (max-width: 500px) {
    .selectboxkisan{
        display: none;
    }
  }
@media (max-width: 450px) {
    .kishan-Box{
        background-image: url(./images/kishsn\ morcha\ 800.jpg);
        background-size: cover; 
        background-repeat: no-repeat;
        height: 80vh;
        margin-bottom: 20px;
    }
    .selectboxkisan{
        position: absolute;
        top: 95%;
        left: 15%;
        width: 70%;
        z-index: 10;
    }
  }
