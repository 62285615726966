.background-image-div-home {
    background-image: url(./home/desktop.jpeg);
    background-size: 100% 100%; /* <------ */
    background-repeat: no-repeat;
    height: 120vh;
    background-position: center center;
  }
  .home-button-morcha {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 70%;
    width: 95%;
    gap: 50px;
  }
  .home-button-morcha a{
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  /* .home-button-morcha > div {
    background-color: rgb(208, 208, 208);
  }
  .home-button-morcha > div button {
    background-color: greenyellow;
    width: 90%;
  } */

  @media (max-width: 900px) {
    .background-image-div-home {
      background-image: url(./home/IMG_1888.jpeg);
      background-size: 100% 100%; /* <------ */
      background-repeat: no-repeat;
      height: 120vh;
      background-position: center center;
    }
  }
  @media (max-width: 350px) {
    .background-image-div-home {
      background-image: url(./home/mobile.jpeg);
      background-size: 100% 100%; /* <------ */
      background-repeat: no-repeat;
      height: 120vh;
      background-position: center center;
    }
  }